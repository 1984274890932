import React from 'react'
import { NavLink } from 'react-router-dom'
import style from './navigation.module.scss'
import * as ApiRoutes from '../../api/apiRoutes';

//Used for other pages, shown on the left side of the menu
class MainNavigation extends React.Component {
    render() {
        return <nav id="Navigation" className={style.main}>
            <ul>
                <li>
                    <NavLink activeClassName="active" to="/berichten">Berichten</NavLink>
                </li>
            </ul>
        </nav>
    }
}

//Used for functionalities, shown on the right side of the menu
class SecondaryNavigation extends React.Component {
    render() {
        return <div className={style.secondary}>
            <ul>
                <li>
                    <a className="active" href={ApiRoutes.LogoutUser2}>Uitloggen</a>
                </li>
            </ul>
        </div>

    }
}


export {
    MainNavigation,
    SecondaryNavigation
};