import React from 'react';
import PropTypes from 'prop-types';
import styles from './buttons.module.scss';


/**
 * Create regular button without icons
 *
 * @param {any} {children}
 * @returns
 */
const ButtonRegular = ({ children, id, className, ...rest }) => {

    let composedClassName;

    className!=null 
        ? composedClassName = className + " " + styles.buttonRegular
        : composedClassName = styles.buttonRegular

    return (
        <button id={`btnReg-${id}`} className={composedClassName} {...rest}> {children}</button>
    );
};


/**
 * Create regular button without icons
 *
 * @param {any} {children}
 * @returns
 */
const ButtonLink = ({ children, id, className, ...rest }) => {

    let composedClassName;

    className!=null 
        ? composedClassName = className + " " + styles.buttonLink
        : composedClassName = styles.buttonLink

    return (
        <button id={`btnLink-${id}`} className={composedClassName} {...rest}> {children}</button>
    );
};



/**
 * Create button with Font-Awesome icon
 *
 * @param {any} {children}
 * @returns
 */
const ButtonWithIcon = ({ children, id, icon, className, ...rest }) => {
    
    let composedClassName;

    className!=null 
        ? composedClassName = className + " " + styles.buttonWithIcon
        : composedClassName = styles.buttonWithIcon

        if(icon!=null) {} composedClassName = composedClassName + " " + styles[icon]

    return (
        <button id={`btnIcon-${id}`} className={composedClassName} {...rest}> {children}</button>
    );
};


const propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired
};

ButtonRegular.propTypes = propTypes;
ButtonLink.propTypes = propTypes;
ButtonWithIcon.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    icon: PropTypes.string
};

export {
    ButtonRegular,
    ButtonLink,
    ButtonWithIcon
};