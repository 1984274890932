/*
    This file is generated on 28/04/2020 by the CreateJsWorkingModels T4 template, located in Studielink.FrontEnd.Model.WorkingModel project.
*/
export const BerichtenDetailsWorkingModel = {
    /**
     * CSharp Type: System.Int64
     */
    Id: 'id',
    /**
     * CSharp Type: System.String
     */
    BerichtInhoud: 'berichtInhoud',
    /**
     * CSharp Type: System.String
     */
    BerichtStatus: 'berichtStatus'
};

export const BerichtenFilterWorkingModel = {
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    StartDateTime: 'startDateTime',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    EndDateTime: 'endDateTime',
    /**
     * CSharp Type: System.String
     */
    BerichtType: 'berichtType',
    /**
     * CSharp Type: System.String
     */
    StatusVerwerking: 'statusVerwerking',
    /**
     * CSharp Type: System.String
     */
    Bsnnummer: 'bsnnummer',
    /**
     * CSharp Type: System.String
     */
    Onderwijsnummer: 'onderwijsnummer'
};

export const BerichtenWorkingModel = {
    /**
     * CSharp Type: System.Int64
     */
    BerichtId: 'berichtId',
    /**
     * CSharp Type: System.String
     */
    BerichtBedrijfsdocumentId: 'berichtBedrijfsdocumentId',
    /**
     * CSharp Type: System.String
     */
    BerichtType: 'berichtType',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumOntvangen: 'datumOntvangen',
    /**
     * CSharp Type: System.String
     */
    StatusVerwerking: 'statusVerwerking',
    /**
     * CSharp Type: System.String
     */
    Richting: 'richting',
    /**
     * CSharp Type: System.String
     */
    TypeFoutmelding: 'typeFoutmelding',
    /**
     * CSharp Type: System.String
     */
    Bsnnummer: 'bsnnummer',
    /**
     * CSharp Type: System.String
     */
    Onderwijsnummer: 'onderwijsnummer',
    /**
     * CSharp Type: System.String
     */
    Inschrijfvolgnummer: 'inschrijfvolgnummer',
    /**
     * CSharp Type: System.String
     */
    Resultaatvolgnummer: 'resultaatvolgnummer'
};

export const ErrorBerichtenModel = {
    /**
     * CSharp Type: System.String
     */
    BerichtType: 'berichtType',
    /**
     * CSharp Type: System.Nullable<System.DateTime>
     */
    DatumOntvangen: 'datumOntvangen',
    /**
     * CSharp Type: System.String
     */
    StatusVerwerking: 'statusVerwerking',
    /**
     * CSharp Type: System.String
     */
    ErrorReason: 'errorReason'
};

export const UserDetailsModel = {
    /**
     * CSharp Type: System.String
     */
    Email: 'email',
    /**
     * CSharp Type: System.String
     */
    Password: 'password',
    /**
     * CSharp Type: System.String
     */
    RegisterToken: 'registerToken'
};
