import React from 'react';
import ReactDataGrid from 'react-data-grid';
import {Filters} from 'react-data-grid-addons';
import moment from 'moment'

import { BerichtenWorkingModel } from '../../constants/workingModels';
import style from './berichtenGrid.module.scss'
import { Ordering, BerichtenWorkingModelExtension, StatusVerwerking } from '../../constants/constants';

//SEE: https://adazzle.github.io/react-data-grid/docs/examples/simple-grid

const {
SingleSelectFilter
}= Filters;

//This constant defines the available columns in the ReactDataGrid
const columns = [
    { key: 'icon', name: '', width: 40 },
    { key: BerichtenWorkingModel.BerichtType, name: 'Type', filterable: true, filterRenderer:SingleSelectFilter, width: 280 },
    { key: BerichtenWorkingModelExtension.LocalDatumOntvangen, name: 'Datum tijd', sortable: true, },
    { key: BerichtenWorkingModel.StatusVerwerking, name: 'Status', filterable: true, filterRenderer:SingleSelectFilter },
    { key: BerichtenWorkingModel.Richting, name: 'Richting', width: 80 },
    { key: BerichtenWorkingModel.TypeFoutmelding, name: 'Fouttype' },
    { key: BerichtenWorkingModel.Bsnnummer, name: 'Burgerservicenr.', filterable: true, width: 140 },
    { key: BerichtenWorkingModel.Onderwijsnummer, name: 'Onderwijsnr.', filterable: true, width: 140 },
    { key: BerichtenWorkingModel.Inschrijfvolgnummer, name: 'Inschrijfvolgnr.', events: {} },
    { key: BerichtenWorkingModel.Resultaatvolgnummer, name: 'Resultaatvolgnr.', }
].map(c => ({ ...c,  }));

const EmptyRowsView = () => {
    return (
        <div className={style.noBerichten}>
            <span>Er zijn geen berichten gevonden</span>
        </div>
    );
};

function getValidFilterValues(columnId) {
    var bericTypeArray = [
        "AanleverenInschrijving",
        "AanleverenVerwijderingInschrijving",
        "AanleverenResultaat",
        "AanleverenVerwijderingResultaat",
        "AanleverenWijzigingPersoon",
        "BeeindigenAbonnementPersoon",
        "IdentificerenPersoon",
        "IdentificerenPersoonZonderBsn",
        "AanvragenIndicatieSoortCollegegeld",
        "AanvragenVerstrekkenVooropleidingen",
        "AanvragenVerstrekkenVooropleidingenV2",
        "TerugkoppelenInschrijving",
        "TerugkoppelenVerwijderingInschrijving",
        "TerugkoppelenResultaat",
        "TerugkoppelenVerwijderingResultaat",
        "TerugkoppelenWijzigingPersoon",
        "TerugkoppelenIndicatieSoortCollegegeld",
        "VerstrekkenVoorlopigeBekostigingstatus",
        "VerstrekkenPersoonsgegevens",
        "VerstrekkenVooropleidingsgegevens",
        "VerstrekkenVooropleidingsgegevensV2"];  
            
    var StatusVerwerkingArray = [
        "Received",
		"Acknowledged",
		"Send",
		"Retrying",
		"Error",
		"Replied"];

        if (columnId === 'berichtType'){
            return bericTypeArray;
        }
        else if(columnId === "statusVerwerking"){
            return StatusVerwerkingArray;
        } 
  }

class BerichtenGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clickedRow: null,
            selectedIndexes: [], //selectedIndexes is used to resend berichten
            berichtenID: [],
            filterParams: {}
        };
    };

    componentDidMount() {
        //Opens filteroptions on mounting (standard ReactDataGrid function). Is couple via ref={this.berichtenGrid}
        this.berichtenGrid.onToggleFilter();
    }

    componentDidUpdate(prevProps, prevState) {

        //If the user changes the page-number, we will have to reset the selectedIndexes (used for Resend or Delete buttons)
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState({ berichtenID: [], selectedIndexes: [] });
            //Also uncheck the checkbox at the top of the grid (the selectAllCheckbox)
            this.berichtenGrid.selectAllCheckbox.checked = false;
        }

        //If we have received a new set of rows (the user has clicked the refresh button); we should reset the sorting
        if (prevProps.rows !== this.props.rows) {
            this.berichtenGrid.setState({ sortColumn: BerichtenWorkingModelExtension.LocalDatumOntvangen, sortDirection: Ordering.ASC })
        }


        //We are clearing the state when an action on the grid is performed (e.g. Resend or Delete)
        if (this.props.clearState === true) {
            this.setState({ berichtenID: [], selectedIndexes: [], clickedRow: null })
            //We should also uncheck the selectAllChecbox on the topleft of the grid
            this.berichtenGrid.selectAllCheckbox.checked = false;
            //reset the clear state attribute
            this.props.resetClearState();
        }

    }

    //Lets use the RowRenderer of React-data-grid to add custom styling for Request/Response berichten
    renderRowsInGrid = ({ renderBaseRow, ...props }) => {
        //Because the react-data-grid package uses their own stylesheet which we cannot overwrite,
        //We are adding 'normal' classnames here
        let className = 'regularRow';

        //Has a parent? Then it is a child
        if (props.row[BerichtenWorkingModelExtension.HasRequest] != null) {
            className = 'childRow';
        }
        //Has children? Then it is a parent
        if (props.row[BerichtenWorkingModelExtension.HasResponses] != null) {
            if (props.row[BerichtenWorkingModelExtension.HasResponses].length > 0) {
                className = 'parentRow';
            }
        }
        if (props.row[BerichtenWorkingModel.StatusVerwerking] != null) {
            if (props.row[BerichtenWorkingModel.StatusVerwerking] === StatusVerwerking.Error) {
                className += " message-has-error";
            }
        }

        className += props.idx % 2 ? " oddRow" : " evenRow";

        if (props.isSelected) { className += " selectedRow"; } //Highlight all the rows that are selected

        if (props.idx === this.state.clickedRow) { className += " clickedRow"; } //Highlight the row clicked by the user

        return <div
            id={`bericht-${props.row[BerichtenWorkingModel.BerichtId]}`}
            key={`bericht-${props.row[BerichtenWorkingModel.BerichtId]}`}
            className={className}
        >
            {renderBaseRow(props)}
        </div>;
    };

    onRowClick = (rowIdx, row) => {
        if (row != null && rowIdx > -1 && row[BerichtenWorkingModel.BerichtId] != null) {
            //When a row is clicked in the grid pass up berichtId of that row to the parent page/berichten
            this.props.selectBericht(row[BerichtenWorkingModel.BerichtId], row[BerichtenWorkingModel.StatusVerwerking]);
            //Add clickedRow to state to be able to highlight the row the user has clicked
            this.setState({ clickedRow: rowIdx })
        }
    };

    sortRows = (sortColumn, sortDirection) => {
        //We don't want an NONE option for ordering (default in  the react-data-grid)
        //So let's return ASC instead
        if (sortDirection === Ordering.NONE) {
            sortDirection = Ordering.ASC;
        }
        this.berichtenGrid.setState({ sortColumn: BerichtenWorkingModelExtension.LocalDatumOntvangen, sortDirection: sortDirection })
        
        //For now we are only sorting on the datumOntvangen column. So we don't need to pass the sortColumn
        this.props.changeSorting(sortDirection);
    }

    onRowsSelected = rows => {
        //If a checkbox is newly clicked add it to the list of checked berichten
        //This list can be used to resend or delete berichten

        if (rows != null) {
            let newSelectedIndexes = this.state.selectedIndexes.concat(
                rows.map(r => r.rowIdx)
            );

            let newBerichtenID = this.state.berichtenID.concat(
                rows.map((r) => {
                    if (r.row != null) {
                        return r.row[BerichtenWorkingModel.BerichtId]
                    }
                    return null;
                })
            );

            this.setState({ selectedIndexes: newSelectedIndexes, berichtenID: newBerichtenID });
            this.props.selectedBerichten(newBerichtenID)
        } else {
            console.error('reactDataGrid returned rows that are null or undefined');
        }

    };

    onRowsDeselected = rows => {
        //If a checkbox was previously clicked and is now clicked again remove it from the list of checked berichten
        //This list can be used to resend or delete berichten
        if (rows != null) {
            let rowIndexes = rows.map(r => r.rowIdx);
            let newSelectedIndexes = this.state.selectedIndexes.filter(
                i => rowIndexes.indexOf(i) === -1
            );

            let berichtenID = rows.map((r) => {
                if (r.row != null) {
                    return r.row[BerichtenWorkingModel.BerichtId]
                }
                return null;
            });
            let newBerichtenID = this.state.berichtenID.filter(
                i => berichtenID.indexOf(i) === -1
            );

            this.setState({ selectedIndexes: newSelectedIndexes, berichtenID: newBerichtenID });
            this.props.selectedBerichten(newBerichtenID)
        } else {
            console.error('reactDataGrid return an rows that is null or undefined');
        }
    };

    handleFilterChange = filter => filters => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    };

    onFilterChangeHandler = (filterParams) => {
        if (filterParams.filterTerm === Object(filterParams.filterTerm) && filterParams.filterTerm !== null){
            const newFilterKey = {
                [filterParams.column.key]: filterParams.filterTerm.value,
            };
            this.props.onFilterChange(newFilterKey);
        }
        else if(filterParams.filterTerm === null){
            const newFilterKey2 = {
                [filterParams.column.key]: "",
            };
            this.props.onFilterChange(newFilterKey2);
        }
        if (typeof filterParams.filterTerm === 'string' || filterParams.filterTerm instanceof String){
            const newFilterKey3 = {
                [filterParams.column.key]: filterParams.filterTerm,
            };
            this.props.onFilterChange(newFilterKey3);
        }    
    };
    
    showBerichtenInGrid = (index) => {
        //Make sure pagination works
        //Multiply the currentpage by the pagesize and substract the total pagesize to get the right berichten
        let startOfBerichten = (this.props.currentPage * this.props.pageSize) - this.props.pageSize;
        let actualBerichtIndex = startOfBerichten + index;

        //We need to convert the JSON Object to an array for the RowGetter function from ReactDataGrid to parse the details
        let rowJsonObject = this.props.rows[actualBerichtIndex];


        if (rowJsonObject != null) {
            //Convert the datetime in utc to current datetime and cut off milliseconds
            let dateTimeUtc = moment.utc(rowJsonObject[BerichtenWorkingModel.DatumOntvangen]); //Convert to the right UTC format with help of MomentJS
            let localDateTime = moment(dateTimeUtc).local().format("DD-MM-YYYY HH:mm:ss");; //Convert the UTC time to the local time of the user
            //Add the local dateTime as property to the row that is currently shown
            rowJsonObject[BerichtenWorkingModelExtension.LocalDatumOntvangen] = localDateTime;


            return rowJsonObject;
        }

        return null;
    };

    render() {

        //We need to return 0 on the rowCount to make sure the BerichtenGrid displays EmptyRowsView
        //If we do have rows we will use the pageSize as the rowCount
        let rowCount;
        this.props.rows.length === 0
            ? rowCount = 0
            : this.props.rows.length < this.props.pageSize
                ? rowCount = this.props.rows.length
                : rowCount = this.props.pageSize;
        //The minimum height of the grid is directly related to the amount of rows
        let minHeight;
        if(rowCount === 0){
            minHeight = 100;
        }
        else if (rowCount < 5){
            minHeight = 100 + (5*35);
        }
        else{
            minHeight = 100 + (rowCount*35);
        }
        return <div id="BerichtenGrid">
            <ReactDataGrid
                columns={columns}
                rowGetter={this.showBerichtenInGrid}
                rowRenderer={this.renderRowsInGrid}
                rowsCount={rowCount}
                minHeight={minHeight}
                onRowClick={this.onRowClick}
                onGridSort={this.sortRows}
                sortDirection={Ordering.ASC}
                enableRowSelect={null /*is depricated. set to null to remove warning*/}
                rowSelection={{
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: this.onRowsSelected,
                    onRowsDeselected: this.onRowsDeselected,
                    selectBy: {
                        indexes: this.state.selectedIndexes
                    }
                }}
                ref={(datagrid) => { this.berichtenGrid = datagrid; }}
                onAddFilter={this.onFilterChangeHandler}
                emptyRowsView={EmptyRowsView}
                getValidFilterValues={columnKey => getValidFilterValues(columnKey)}
            />
        </div>
    }
}

export default BerichtenGrid