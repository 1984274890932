import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, RefreshControls, LoadingSpinner } from '../molecules';
import style from './refreshBar.module.scss';


class RefreshBar extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            selectedStartDate:null,
            selectedEndDate: null
        };     
    }

    // Function to get the selected start date from child component
    retrieveSelectedStartDate = (selectedStartDt) => {
        this.setState({
            selectedStartDate: selectedStartDt
        });
    }
    retrieveSelectedEndDate = (selectedEndDt) => {
        this.setState({
            selectedEndDate: selectedEndDt
        });
    }

    render() {
        return <div id="RefreshBar" className={style.refreshBar}>
            <DatePicker component='start' onApply={this.props.onDateChange} selectedStartDate={null} selectedEndDate={this.state.selectedEndDate} getSelectedStartDate={this.retrieveSelectedStartDate} className={style.datePickerStart} />
            <DatePicker component='end' onApply={this.props.onDateChange} selectedStartDate={this.state.selectedStartDate} selectedEndDate={null} getSelectedEndDate={this.retrieveSelectedEndDate} className={style.datePickerEnd} />
            {/*The loadingSpinner is activated through react-promise-tracker. It keeps track of API calls and shows the spinner when the call takes longer than 500ms.*/}
            <div className={style.loadingSpinner}>
                <LoadingSpinner />
            </div>
            <RefreshControls
                refreshGrid={() => this.props.refreshGrid(true)} //Receive a click on the refresh button in molecules/refreshControlsand pass it up to page/berichten
            />
        </div>;

    }
}

RefreshBar.propTypes = {
    refreshGrid: PropTypes.func.isRequired,
};

export default RefreshBar;