import React from 'react';
import PropTypes from 'prop-types';
import styles from './faIcon.module.scss';

/**
 * Renders a font awesome icon based on the supplied icon name.
 *
 * Optional: provide additional classes through the className property.
 *
 * @param {any} {icon, className, srText}
 * @returns
 */

const FaIcon = ({ icon, id }) => {

    //Create classname out of 'faIcon' and the given icon
    let className = styles.faIcon;
    if (icon != null) { className += ' ' + styles[icon] }

    return <span id={`faIcon-${id}`} className={className} />;
};


FaIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    className: PropTypes.string
};


export default FaIcon;