import React from 'react'
import { MainNavigation, SecondaryNavigation } from '../molecules'
import style from './header.module.scss'

class Header extends React.Component {
    render() {
        return <header id="Header" className={style.header}>
            <div className="col-lg-10 ml-lg-auto mr-lg-auto">
                <div className={style.logo}></div>
                <MainNavigation />
                <SecondaryNavigation />
            </div>
        </header>
    }
}


export default Header