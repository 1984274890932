import React from 'react'
import style from './berichtenDetail.module.scss'

class BerichtenDetail extends React.Component {

    render() {
        return <div id="BerichtenDetail">
            <div className={style.xml_code}>
                <textarea readOnly={true} value={this.props.berichtInhoud}>
                </textarea>
            </div>
        </div>

    }
}


export default BerichtenDetail