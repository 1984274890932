import React from 'react';
import { PagingControls, PagePicker } from '../molecules';
import style from './pagingBar.module.scss';
import { Buttons } from '../atoms';


class PagingBar extends React.Component {


    selectPageSize = (pageSizeFromPagePicker) => {
        //Receive the picked size from molecules/pagePicker and pass it up to pages/berichten
        this.props.selectPageSize(pageSizeFromPagePicker);
    };

    selectCurrentPage = (currentPageFromPagingControls) => {
        //Receive the current selected page from molecules/pagingControls and pass it up to pages/berichten
        this.props.selectCurrentPage(currentPageFromPagingControls);
    };

    render() {
        return <div id="PagingBar" className={style.pagingBar}>
            <div className={`${style.controlRow} row`}>
                <Buttons.ButtonWithIcon id="resend" icon='resend' onClick={() => this.props.showResendModal()}>Verstuur opnieuw</Buttons.ButtonWithIcon>
                <Buttons.ButtonWithIcon id="delete" icon='delete' onClick={() => this.props.showDeleteModal()}>Verwijderen</Buttons.ButtonWithIcon>
            </div>
            <PagePicker
                selectPageSize={this.selectPageSize}
                pageSize={this.props.pageSize}
            />
            <PagingControls
                selectCurrentPage={this.selectCurrentPage}
                currentPage={this.props.currentPage}
                maxPage={this.props.maxPage}
            />
        </div>;

    }
}
export default PagingBar;