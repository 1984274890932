
import React from 'react';
import { Buttons } from '../atoms';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import style from './modal.module.scss';
import * as ApiRoutes from '../../api/apiRoutes';

class ModalDialog extends React.Component {

  render() {
    let bodyPart1, bodyPart2;
    if (this.props.modal.redirectLink === true){
      let arrayOfBody = this.props.modal.body.split('*');
      bodyPart1 = arrayOfBody[0];
      bodyPart2 = arrayOfBody[1];
    }
    return this.props.modal.isOpen === true && this.props.modal.title != null && this.props.modal.body != null
      ? <div id="Modal">
        <Modal.Dialog className={style.modal}>
          <Modal.Header>
            <Modal.Title>{this.props.modal.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {(this.props.modal.redirectLink === true) ?<div><p>{bodyPart1} <a href={ApiRoutes.LoginUser2}>hier</a> {bodyPart2}</p></div> 
            : <p>{this.props.modal.body}</p> 
                
            }               
          </Modal.Body>

          <Modal.Footer>
            <Buttons.ButtonLink id="ModalAnnuleren" onClick={this.props.closeModalDialog}>Annuleren</Buttons.ButtonLink>
            {this.props.modal.buttons != null
              ? this.props.modal.buttons.map((button, key) =>
                button.trigger != null && button.text != null
                  ? <Buttons.ButtonRegular key={key} id={`Modal${key}`} onClick={button.trigger}>{button.text}</Buttons.ButtonRegular>
                  : null
              )
              : null}
          </Modal.Footer>
        </Modal.Dialog>
        <div className={style.modalOverlay}>
        </div>
      </div>
      : <div />

  }
}


ModalDialog.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    redirectLink: PropTypes.bool.isRequired,
    buttons:  PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      trigger: PropTypes.func
    }))
  })
};



export default ModalDialog;





