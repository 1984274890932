import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import style from './loadingSpinner.module.scss';


const LoadingSpinner = props => {
  //The react-promise-tracker looks at API responses. There is a 500 delay because we don't want to show the loadingSpinner instantly.
  const { promiseInProgress } = usePromiseTracker({delay: 500});

  return (
    //When the Api call is still in progress we show the loadingSpinner. The animation is done in CSS.
    promiseInProgress &&
      <div id="LoadingSpinner" className={style.wrapper}>
        <span className={style.text}>Bezig met laden.</span>
        <div className={style.loadingSpinner}>
          <span className={style.spinnerDot1}></span>
          <span className={style.spinnerDot2}></span>
          <span className={style.spinnerDot3}></span>
        </div>
      </div>
  );
}

export default LoadingSpinner;