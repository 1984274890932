/**
 * Constants for ordering the BerichtenGrid
 */
export const Ordering = {
    NONE: 'NONE',
    DESC: 'DESC',
    ASC: 'ASC'
};

/**
 * Constants for adding additional properties to the rows in the berichten grid
 */
export const BerichtenWorkingModelExtension = {
    LocalDatumOntvangen: 'LocalDatumOntvangen', //For adding the locally converted datumOntvangen to the berichten grid rows
    HasResponses: 'HasResponses', //For adding one or multiple Response Ids so we can group them together
    HasRequest: 'HasRequest' //For adding one id from the Request
};

/**
 * Constants for the status enums of the backend
 */
export const StatusVerwerking = {
    Received : 'Received',
    Acknowledged: 'Acknowledged',
    Send: 'Send',
    Retrying:'Retrying',
    Error: 'Error'
};