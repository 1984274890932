import React from 'react';
import PropTypes from 'prop-types';

const pageSizes = [5, 10, 25, 50, 100];

class PageSizePicker extends React.Component {
    //IMPROVE: this function causes a rerender. To improve performance we should cache it.
    selectPageSize = (ev) => {
        this.props.selectPageSize(ev.target.value);
    };

    render() {
        //Save the pageSize as the defaultValue for the HTML select
        return <select id="PageSizePicker" onChange={this.selectPageSize} defaultValue={this.props.pageSize}>
            {pageSizes.map((value) => {
                return (<option key={value} value={value}>{`${value} resultaten per pagina`}</option>);
            })}
        </select>;
    }
}

PageSizePicker.propTypes = {
    pageSize: PropTypes.number.isRequired,
    selectPageSize: PropTypes.func.isRequired
};


export default PageSizePicker;