import React from 'react';
import PropTypes from 'prop-types';
import { DatetimeRangePicker } from './index';
import moment from 'moment';
import 'moment/locale/nl';

import { Buttons } from '../atoms';

var minDateStart, maxDateStart, minDateEnd, maxDateEnd;
//const today = new Date();
const OdataFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
const locale = {
    format: 'LLL', //displayformat
    separator: ' - ',
    applyLabel: 'Pas toe',
    cancelLabel: 'Annuleren',
    weekLabel: 'W',
    customRangeLabel: 'Custom',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.months(),
    firstDay: moment.localeData().firstDayOfWeek(),
};


class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        
        const ranges = this.getRanges();

        const defaultRange = typeof props.defaultRange === 'string' && Array.isArray(ranges[props.defaultRange]) ? ranges[props.defaultRange] : [];
        
        this.state = {
            startDate: defaultRange[0],
            endDate: defaultRange[1],
        };     
    }

    componentDidMount() {
        const { onApply, component} = this.props;

        const startDate = moment(this.state.startDate, 'Europa/Amsterdam').utc().format(OdataFormat);
        const endDate = moment(this.state.endDate, 'Europa/Amsterdam').utc().format(OdataFormat);

        component === 'start' ? onApply(startDate === 'Invalid date' ? '' : startDate, 'startDateButton') : component === 'end' ? onApply(endDate === 'Invalid date' ? '' : endDate, 'endDateButton') : onApply();
    }
    
    getRanges() {          
        if (this.props.component === 'end'){
            return {
                'Geen': [moment(maxDateEnd), moment(maxDateEnd)],
            }
        }   
        if (this.props.component === 'start'){
            return {
                'Geen': [moment(new Date().toISOString()), moment(new Date().toISOString())],
            }
        } 
        
    }

    getDisplayStartDate() {
        return this.state.startDate != null ? moment(this.state.startDate).format(locale.format) : undefined;
    }

    getDisplayEndDate() {
        return this.state.endDate != null ? moment(this.state.endDate).format(locale.format) : undefined;
    }

    render() {
        const { onApply, component, selectedStartDate, getSelectedStartDate, selectedEndDate ,getSelectedEndDate } = this.props;
               
        // Limit the range of date selections according to the different cases
        if (component === 'end' && selectedStartDate !== null && selectedStartDate !== undefined){
            minDateEnd = selectedStartDate;
            maxDateEnd = new Date().toISOString();  
        }
        else if (component === 'end' && selectedStartDate === null){
            minDateEnd = '2010-01-01T00:00:00.000Z';
            maxDateEnd = new Date().toISOString();
        }
        if (component === 'start' && selectedEndDate !== null && selectedEndDate !== undefined){
            minDateStart = '2010-01-01T00:00:00.000Z';
            maxDateStart = selectedEndDate;
        }
        else if (component === 'start' && selectedEndDate === null){
            minDateStart = '2010-01-01T00:00:00.000Z';
            maxDateStart = new Date().toISOString(); 
        }
        
        // Depending on which datepicker is selected provide separate rendering
        if (component === 'start') {
            return <DatetimeRangePicker
            className={'datepicker-wrapper ' + this.props.className}
            linkedCalendars={false}
            autoApply={true}
            showDropdowns={true}
            timePicker={true}
            timePicker24Hour={true}
            locale={locale}
            minDate={moment(minDateStart)}
            maxDate={moment(maxDateStart)}
            singleDatePicker={true}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onApply={(event, picker) => {
                if (typeof onApply === 'function') {
                    if (moment(picker.startDate).isBetween(minDateStart, maxDateStart) && moment(picker.endDate).isBetween(minDateStart, maxDateStart)) {
                        const startDate = moment(picker.startDate, 'Europa/Amsterdam').utc().format(OdataFormat);
                        //Mutate enddate to always include the whole minute (so add 59 seconds)
                        const endDate = moment(picker.endDate, 'Europa/Amsterdam').add(59, 'seconds').utc().format(OdataFormat);
                        component === 'start' ? onApply(startDate, 'startDateButton') : component === 'end' ? onApply(endDate, 'endDateButton') : onApply();
                        this.setState({
                            startDate: picker.startDate,
                            endDate: picker.endDate,
                        });
                        getSelectedStartDate(picker.startDate);                      
                    }
                    else {
                        this.setState({
                            startDate: undefined,
                            endDate: undefined,
                        });
                        component === 'start' ? onApply('', 'startDateButton') : component === 'end' ? onApply('', 'endDateButton') : onApply();
                        // When a datetime has been deselected pass a null to the parent component
                        this.props.getSelectedStartDate(null);
                    }
                }
            }
            }
            ranges={this.getRanges()}
        >
            {this.props.component === 'start' ?
                this.state.startDate == null
                    ? <Buttons.ButtonWithIcon id='startDatumKiezen' icon='calendar'>Kies startdatum</Buttons.ButtonWithIcon>
                    : <Buttons.ButtonWithIcon id='startDatumWijzigen' icon='calendar'>{this.getDisplayStartDate()}</Buttons.ButtonWithIcon>
                : null
            }
            {this.props.component === 'end' ?
                this.state.endDate == null
                    ? <Buttons.ButtonWithIcon id='eindDatumKiezen' icon='calendar' >Kies einddatum</Buttons.ButtonWithIcon>
                    : <Buttons.ButtonWithIcon id='eindDatumWijzigen' icon='calendar'>{this.getDisplayEndDate()}</Buttons.ButtonWithIcon>
                : null
            }
        </DatetimeRangePicker>;
        }
        if (component === 'end'){
            return <DatetimeRangePicker
            className={'datepicker-wrapper ' + this.props.className}
            linkedCalendars={false}
            autoApply={true}
            showDropdowns={true}
            timePicker={true}
            timePicker24Hour={true}
            locale={locale}
            minDate={moment(minDateEnd)}
            maxDate={moment(maxDateEnd)}
            singleDatePicker={true}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onApply={(event, picker) => {
                if (typeof onApply === 'function') {
                    if (moment(picker.startDate).isBetween(minDateEnd, maxDateEnd) && moment(picker.endDate).isBetween(minDateEnd, maxDateEnd)) {
                        const startDate = moment(picker.startDate, 'Europa/Amsterdam').utc().format(OdataFormat);
                        //Mutate enddate to always include the whole minute (so add 59 seconds)
                        const endDate = moment(picker.endDate, 'Europa/Amsterdam').add(59, 'seconds').utc().format(OdataFormat);
                        component === 'start' ? onApply(startDate, 'startDateButton') : component === 'end' ? onApply(endDate, 'endDateButton') : onApply();
                        this.setState({
                            startDate: picker.startDate,
                            endDate: picker.endDate,
                        });
                        getSelectedEndDate(picker.startDate);
                                               
                    }
                    else {
                        this.setState({
                            startDate: undefined,
                            endDate: undefined,
                        });
                        component === 'start' ? onApply('', 'startDateButton') : component === 'end' ? onApply('', 'endDateButton') : onApply();
                        // When a datetime has been deselected pass a null to the parent component
                        this.props.getSelectedEndDate(null);
                    }
                }
            }
            }
            ranges={this.getRanges()}
        >

            {this.props.component === 'start' ?
                this.state.startDate == null
                    ? <Buttons.ButtonWithIcon id='startDatumKiezen' icon='calendar'>Kies startdatum</Buttons.ButtonWithIcon>
                    : <Buttons.ButtonWithIcon id='startDatumWijzigen' icon='calendar'>{this.getDisplayStartDate()}</Buttons.ButtonWithIcon>
                : null
            }
            {this.props.component === 'end' ?
                this.state.endDate == null
                    ? <Buttons.ButtonWithIcon id='eindDatumKiezen' icon='calendar' >Kies einddatum</Buttons.ButtonWithIcon>
                    : <Buttons.ButtonWithIcon id='eindDatumWijzigen' icon='calendar'>{this.getDisplayEndDate()}</Buttons.ButtonWithIcon>
                : null
            }
        </DatetimeRangePicker>;
        }
    }
}




DatePicker.propTypes = {
    onApply: PropTypes.func,
};

export default DatePicker;