/* ------ Run via backend ---- */
// Constant with the value "api".
//The API path is build up from constants located in the .env file in the root. That way the path can be environmentally dependent.
export const Api =  process.env.REACT_APP_APIHOST + ':' + process.env.REACT_APP_APIPORT + '/Api';


/**
 * All the constants which stores the corresponding api routing link.
 */
export const Berichten = () => `${Api}/Berichten`;
export const BerichtDetails = () => `${Api}/BerichtenDetails`;
export const DeleteBerichten = () => `${Api}/BerichtenDelete`;
export const ResendBerichten = () => `${Api}/BerichtenResend`;

export const LoginUser = () => `${Api}/UserLogin`;
export const LogoutUser = () => `${Api}/UserLogout`;
export const IsAuthenticatedUser = () => `${Api}/userisauthenticated`;


export const RegisterUser = () => `${Api}/userregister`;

export const LoginUser2 = Api + "/UserLogin";
export const LogoutUser2 = Api + "/Userlogout";