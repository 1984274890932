import React from 'react';
import { Buttons } from '../atoms';
import style from './refreshControls.module.scss'

class RefreshControls extends React.Component {

    render() {

        return <div id="RefreshControls" className={style.refreshControls}>
            <Buttons.ButtonWithIcon
                id="refreshDataGrid"
                icon='refresh'
                onClick={() => this.props.refreshGrid()} //Pass click up to organisms/refreshBar
            >Refresh
            </Buttons.ButtonWithIcon>
        </div>;

    }
}
export default RefreshControls;