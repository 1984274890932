import React from 'react';
import PropTypes from 'prop-types';

import { Pagination } from 'react-bootstrap';
import style from './pagingControls.module.scss';

class PagingBar extends React.Component {

    goToPage = (val) => {
        //IMPROVE: this function causes a rerender. To improve performance we should cache it.
        this.props.selectCurrentPage(val);
    }

    render() {
        return <div id="PagingBar" className="float-right">
            <Pagination className={style.pagingControls}>
                {this.props.currentPage > 1 //Check if we're at the first page
                    ? <React.Fragment>
                        {this.props.currentPage > 3 ? <Pagination.First onClick={() => this.goToPage(1)} /> : null /*Check if we're at the third page, if yes, show 'back to first' button*/}
                        <Pagination.Prev onClick={() => this.goToPage(this.props.currentPage - 1)} />
                        {this.props.currentPage > 2 ? <Pagination.Item onClick={() => this.goToPage(1)} >{1}</Pagination.Item> : null /*Check if we're at the second page, if yes, offer first page as option*/}
                        {this.props.currentPage > 3 ? <Pagination.Ellipsis /> : null /*Check if we're at the third page, if yes, show seperator*/}
                        <Pagination.Item onClick={() => this.goToPage(this.props.currentPage - 1)}>{this.props.currentPage - 1}</Pagination.Item>
                    </React.Fragment>
                    : null}

                <Pagination.Item active>{this.props.currentPage}</Pagination.Item>

                {this.props.currentPage < this.props.maxPage //Check if we're at the last page
                    ? <React.Fragment>
                        {this.props.currentPage < this.props.maxPage - 1 ? <Pagination.Item onClick={() => this.goToPage(this.props.currentPage + 1)} >{this.props.currentPage + 1}</Pagination.Item> : null /*Check of we're at the first-to-laste page, if not, show next button*/}
                        {this.props.currentPage < this.props.maxPage - 2 ? <Pagination.Ellipsis /> : null /*Check if we're at the second-to-last page, if not, show seperator*/}
                        <Pagination.Item onClick={() => this.goToPage(this.props.maxPage)} >{this.props.maxPage}</Pagination.Item>
                        <Pagination.Next onClick={() => this.goToPage(this.props.currentPage + 1)} />
                        {this.props.currentPage < this.props.maxPage - 2 ? <Pagination.Last onClick={() => this.goToPage(this.props.maxPage)} /> : null /*Check if we're at the second-to-last page, if not, show 'to last page' button*/}
                    </React.Fragment>
                    : null}
            </Pagination>
        </div>;

    }
}

PagingBar.propTypes = {
    currentPage: PropTypes.number.isRequired,
    maxPage: PropTypes.number.isRequired,
    selectCurrentPage: PropTypes.func.isRequired
};

export default PagingBar;